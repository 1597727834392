<template>
    <div>
		<div class="header">
			<img class="logo-empresa" :src="reporte.imagen" alt="logo"/>
			<span class="titulo-empresa">{{ reporte.empresa }}</span>
			<span class="direccion">{{ reporte.direccion }}</span>
			<span class="telefono">Telefono: {{ reporte.telefono }}</span>
			<span class="email">Email: {{ reporte.correo }}</span>
			<span class="ubicacion">Ciudad: {{ reporte.ubicacion }}</span>

			<div class="textoborde">
				<p class="no-margin fechareporte"><strong>Fecha: </strong> {{ reporte.fechaIngreso }}</p>
				<p class="no-margin horareporte"><strong>Hora: </strong> {{ reporte.horaIngreso }}</p>
				<p class="no-margin usuario"><strong>Usuario: {{ reporte.usuario.usuario }}</strong></p>
				<p class="no-margin almacen"><strong>{{ reporte.almacen.nombre }}</strong></p>
			</div>

			<span class="documento">{{ reporte.documento }}</span>
		</div>

		<div class="footer">
			<center>
				<div class="css-no_print" align="center">
					<a-button onclick="print_default_printer()" type="primary"><a-icon type="printer" /> IMPRIMIR</a-button>
				</div>
			</center>
		</div>

		<table>
			<thead><tr><th><div class="t-head">&nbsp;</div></th></tr></thead>
			<tbody><tr><td><div class="content">
				<span class="tipoventa" style="color:black; font-size:14px;"><strong>Codigo: </strong> {{ reporte.codigo }} </span>&nbsp;&nbsp;
				
				<div class="arial_font">
					<center>
						<span style="font-weight:700;">Ingreso de: <span class="css-text-12"><span align="center" style="font-size:26px;">{{ reporte.importeIngreso }}  {{ reporte.simbolo }}</span></span></span>
					</center>
				</div>
				<div>
					<strong>Son:</strong> <span class="css-text-12">{{ reporte.montoliteral }}</span>
				</div>
				<div class="arial_font">
					<span><strong>Detalle:</strong> <span class="css-text-12">{{ reporte.detalleIngreso }}</span></span>
				</div>
				<div class="arial_font">
					<span><strong>Concepto:</strong> <span class="css-text-12">{{ reporte.plancuenta.nombre }}</span></span>
				</div>

				<br>

				<table>
					<tbody>
						<tr>
							<td style="width: 30%;">
								<center>
									<span>_________________________________</span><br>
									<span style="font-size:11px; color:black;"><strong>Realizado por: {{ reporte.usuario.usuario }}</strong></span>
								</center>
							</td>
							<td style="width: 10%;"></td>
							<td style="width: 30%;">
								<center>
									<span>_________________________________</span><br>
									<span style="font-size:11px; color:black;"><strong>Autorizado por:</strong></span>
								</center>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<center><span style="font-size:10px; color:black;">Reporte generado por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span></center>
			</div></td></tr></tbody>
			<tfoot><tr><td><div class="t-foot">&nbsp;</div></td></tr></tfoot>
		</table>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.9); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'

  export default {

      props: {
          ingresocajaID: { type: String, required: true }
      },

      data () {
          return {
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('ingresocaja', ['reporte'])
      },

      methods: {
          ...mapActions('ingresocaja', ['getReporte']),

		  imprimir () {
			 this.spinnerloading = true
			 this.getReporte(this.ingresocajaID)
			 .then(response => {
                this.spinnerloading = false
             }).catch(error => {
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
             })
		  }
      },

	  mounted () {
		  this.imprimir()
	  }
  }
</script>

<style scoped>
	@page {
		margin: 0mm;
		size: 218mm; 
		size: portrait;
	}

	@media print {
		body {border: 0;}
		thead {display: table-header-group;} 
		tfoot {display: table-footer-group;}
		button {display: none;}
		body {margin: 0;}
	}

	body {
		margin: 0 auto;
		font-family: 'Space Mono', monospace;
		max-width: 800px;
		background-color: #525252;
		width: 100%;
		border: 1px solid #ffff;
	}

	table {
        width: 100%;
    }

	.tabla-listado {
		background-color: #ffffff; 
		border-top: 0px solid #ffffff; 
		padding: 5px; 
		border-bottom: 1px solid rgb(180, 180, 180);
		font-size: 10px;
	}

		.header, .t-head, .footer, .t-foot {
			height: 100px; max-width: 803px; width: 100%;
			background: #fff;
		}

		.footer, .t-foot { height: 50px; }
		.t-head, .t-foot { background: #fff; }

		.pr {
		position: fixed; right: 0; bottom: 10px;
		color: #aaa; letter-spacing: 1px;
		font-weight: normal; text-decoration: none;
		}

		.header{
			background: #fcfcfc;
			position: fixed; 
			top: 0;
			border-bottom: 1px solid #ffff;
		}

		.footer {
			background: #fcfcfc;
			position: fixed; 
			bottom: 0;
			border-top: 1px solid #ffff;
		}

		.content {
			background: #fefefe;
			padding: 10px;
		}

		.content .page {
			page-break-after: always;
		}

		.logo-empresa{
			position:absolute;
			width:80px;
			height:auto;
			left : 10px;
			z-index: 1;
			top:10px;
		}

		.titulo-empresa{
			position:absolute;
			font-size: 11px;
			color:black;
			font-weight:700;
			letter-spacing: 0.50;
			left : 97px;
			z-index: 1;
			top: 12px;
		}

		.direccion{
			position:absolute;
			font-size: 9px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 28px;
		}

		.telefono{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 42px;
		}

		.celular{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 56px;
		}

		.email{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 55px;
		}

		.ubicacion{
			position:absolute;
			font-size: 9px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 68px;
		}

		.textoborde {
			position:absolute;
			width: 170px;
			height: 70px;
			background-color:#F5F5F5;
			border:1.2px solid #DDDDDD;  
			text-align: right;
			line-height : 1px;
			overflow:hidden;
			padding:5px;
			-webkit-border-radius: 4px;
			border-radius: 4px;
			left : 590px;
			z-index: 1;
			top:20px;
			padding-top: 5px;
			padding-right: 5px;
			padding-bottom: 0px;
			padding-left: 5px;
		}

		.fechareporte{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 10px;
		}

		.horareporte{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 23px;
		}

		.usuario{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 38px;
		}

		.almacen{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 53px;
		}

		.css-data_table{
			width: 100%;
			border: none;
			border-collapse: collapse;
			border-spacing: 2px 0px;
		}
				
		.css-data_table th {
			border-bottom: 1px solid rgb(14, 14, 14);      
			font-weight: 700;
			text-align: center;
			font-size: 0.9em;
			font-weight:700;
		}

		.css-data_table td{
			border: 1px solid black;
			padding: 5px;
			text-align: center;
			font-size: 0.6em;
			font-weight:400;
		}

		.documento{
			position:absolute;
			font-size: 18px;
			font-weight:700;
			color:black;
			left : 260px;
			z-index: 1;
			top: 50px;
		}

		.fecha{
			position:absolute;
			font-size: 13px;
			font-weight:40;
			color:black;
			left : 320px;
			z-index: 1;
			top: 60px;
		}
</style>